import { Availability, Parent, Student, Weekday } from 'types';

export interface TeacherAvailability extends Availability {
  type: 'recurring' | 'one_time';
  booking: {
    openings: number;
    level: number;
    students: (Pick<Student, 'id' | 'username' | 'picture' | 'level'> & {
      parent: Pick<
        Parent,
        'id' | 'fullName' | 'preferredName' | 'phone' | 'primaryEmail'
      >;
    })[];
  } | null;
  classes:
    | {
        id: string;
        datetime: string;
        durationInMinutes: number;
        level: number;
        isTrial: boolean;
      }[]
    | null;
}

export interface Option<T = string> {
  code: T;
  string: string;
}

export const DAYS: Option<Weekday>[] = [
  {
    code: 'SUNDAY',
    string: 'Sunday'
  },
  {
    code: 'MONDAY',
    string: 'Monday'
  },
  {
    code: 'TUESDAY',
    string: 'Tuesday'
  },
  {
    code: 'WEDNESDAY',
    string: 'Wednesday'
  },
  {
    code: 'THURSDAY',
    string: 'Thursday'
  },
  {
    code: 'FRIDAY',
    string: 'Friday'
  },
  {
    code: 'SATURDAY',
    string: 'Saturday'
  }
];

export const TIMES: (Option & { label: string })[] = [
  {
    code: '00:00:00',
    string: '12:00 AM',
    label: ''
  },
  {
    code: '00:30:00',
    string: '12:30 AM',
    label: ''
  },
  {
    code: '01:00:00',
    string: '1:00 AM',
    label: '1 AM'
  },
  {
    code: '01:30:00',
    string: '1:30 AM',
    label: ''
  },
  {
    code: '02:00:00',
    string: '2:00 AM',
    label: '2 AM'
  },
  {
    code: '02:30:00',
    string: '2:30 AM',
    label: ''
  },
  {
    code: '03:00:00',
    string: '3:00 AM',
    label: '3 AM'
  },
  {
    code: '03:30:00',
    string: '3:30 AM',
    label: ''
  },
  {
    code: '04:00:00',
    string: '4:00 AM',
    label: '4 AM'
  },
  {
    code: '04:30:00',
    string: '4:30 AM',
    label: ''
  },
  {
    code: '05:00:00',
    string: '5:00 AM',
    label: '5 AM'
  },
  {
    code: '05:30:00',
    string: '5:30 AM',
    label: ''
  },
  {
    code: '06:00:00',
    string: '6:00 AM',
    label: '6 AM'
  },
  {
    code: '06:30:00',
    string: '6:30 AM',
    label: ''
  },
  {
    code: '07:00:00',
    string: '7:00 AM',
    label: '7 AM'
  },
  {
    code: '07:30:00',
    string: '7:30 AM',
    label: ''
  },
  {
    code: '08:00:00',
    string: '8:00 AM',
    label: '8 AM'
  },
  {
    code: '08:30:00',
    string: '8:30 AM',
    label: ''
  },
  {
    code: '09:00:00',
    string: '9:00 AM',
    label: '9 AM'
  },
  {
    code: '09:30:00',
    string: '9:30 AM',
    label: ''
  },
  {
    code: '10:00:00',
    string: '10:00 AM',
    label: '10 AM'
  },
  {
    code: '10:30:00',
    string: '10:30 AM',
    label: ''
  },
  {
    code: '11:00:00',
    string: '11:00 AM',
    label: '11 AM'
  },
  {
    code: '11:30:00',
    string: '11:30 AM',
    label: ''
  },
  {
    code: '12:00:00',
    string: '12:00 PM',
    label: '12 PM'
  },
  {
    code: '12:30:00',
    string: '12:30 PM',
    label: ''
  },
  {
    code: '13:00:00',
    string: '1:00 PM',
    label: '1 PM'
  },
  {
    code: '13:30:00',
    string: '1:30 PM',
    label: ''
  },
  {
    code: '14:00:00',
    string: '2:00 PM',
    label: '2 PM'
  },
  {
    code: '14:30:00',
    string: '2:30 PM',
    label: ''
  },
  {
    code: '15:00:00',
    string: '3:00 PM',
    label: '3 PM'
  },
  {
    code: '15:30:00',
    string: '3:30 PM',
    label: ''
  },
  {
    code: '16:00:00',
    string: '4:00 PM',
    label: '4 PM'
  },
  {
    code: '16:30:00',
    string: '4:30 PM',
    label: ''
  },
  {
    code: '17:00:00',
    string: '5:00 PM',
    label: '5 PM'
  },
  {
    code: '17:30:00',
    string: '5:30 PM',
    label: ''
  },
  {
    code: '18:00:00',
    string: '6:00 PM',
    label: '6 PM'
  },
  {
    code: '18:30:00',
    string: '6:30 PM',
    label: ''
  },
  {
    code: '19:00:00',
    string: '7:00 PM',
    label: '7 PM'
  },
  {
    code: '19:30:00',
    string: '7:30 PM',
    label: ''
  },
  {
    code: '20:00:00',
    string: '8:00 PM',
    label: '8 PM'
  },
  {
    code: '20:30:00',
    string: '8:30 PM',
    label: ''
  },
  {
    code: '21:00:00',
    string: '9:00 PM',
    label: '9 PM'
  },
  {
    code: '21:30:00',
    string: '9:30 PM',
    label: ''
  },
  {
    code: '22:00:00',
    string: '10:00 PM',
    label: '10 PM'
  },
  {
    code: '22:30:00',
    string: '10:30 PM',
    label: ''
  },
  {
    code: '23:00:00',
    string: '11:00 PM',
    label: '11 PM'
  },
  {
    code: '23:30:00',
    string: '11:30 PM',
    label: ''
  }
];
