import React, { FC, ReactElement } from 'react';
import { Match, MatchRenderProps } from '@reach/router';
import { withLayout, withSEO } from 'components/_hoc';
import FeedbackPage from './FeedbackPageContainer';

const FeedbackPageWithMatch: FC<{}> = () => {
  return (
    <Match path="/teacher/home/history/:classId">
      {({ match: { classId } }: MatchRenderProps<any>): ReactElement => (
        <FeedbackPage classId={classId as string} />
      )}
    </Match>
  );
};

export default withSEO(withLayout(FeedbackPageWithMatch), {
  title: 'Feedback • Teacher',
  description: 'Class feedback.',
  pathname: '/teacher/home/history'
});
