import React from 'react';
import type { FC } from 'react';
import { useFormik } from 'formik';
import { listTimeZones } from 'timezone-support';

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Stack,
  useToast,
} from '@chakra-ui/core';

import API from 'api';
import {
  ButtonGatsbyLink,
  EinsteinButton,
  EinsteinInput,
  EinsteinSelect,
  EinsteinTextarea,
} from 'components/_lib';
import type { Teacher } from 'types';
import EditableAvatar from './EditableAvatar';

const TIMEZONES = listTimeZones();

// Formik form value types
interface EditProfileFormValues {
  fullName: string;
  preferredName: string;
  primaryEmail: string;
  alternateEmail: string;
  bio: string;
  phone: string;
  timezone: string;
}

// EditProfileForm component prop types
interface EditProfileFormProps {
  profile: Teacher;
}

/**
 * Form to edit parent profile details.
 */
const EditProfile: FC<EditProfileFormProps> = ({
  profile,
}: EditProfileFormProps) => {
  // Request status toast
  const toast = useToast();

  // Form state and handlers
  const formik = useFormik<EditProfileFormValues>({
    initialValues: {
      fullName: profile.fullName,
      preferredName: profile.preferredName,
      primaryEmail: profile.primaryEmail,
      alternateEmail: profile.alternateEmail || '',
      bio: profile.bio || '',
      phone: profile.phone,
      timezone: profile.timezone,
    },
    validate: (values) => {
      const errors: Partial<EditProfileFormValues> = {};

      if (!values.fullName) {
        errors.fullName = 'Cannot be empty.';
      }
      if (!values.preferredName) {
        errors.preferredName = 'Cannot be empty.';
      }
      if (!values.primaryEmail) {
        errors.primaryEmail = 'Cannot be empty';
      }
      if (!values.phone) {
        errors.phone = 'Cannot be empty';
      }
      if (!values.timezone) {
        errors.timezone = 'Cannot be empty';
      }

      return errors;
    },
    onSubmit: async ({
      fullName,
      preferredName,
      primaryEmail,
      alternateEmail,
      bio,
      phone,
      timezone,
    }) => {
      try {
        await API.patch(`/teachers/user`, {
          fullName,
          preferredName,
          primaryEmail,
          alternateEmail: alternateEmail === '' ? null : alternateEmail,
          bio: bio === '' ? null : bio,
          phone,
          timezone,
        });
        toast({
          title: 'Success!',
          description: 'Your account has been updated.',
          status: 'success',
          duration: 4500,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Could not update account.',
          status: 'error',
          duration: 4500,
          isClosable: true,
        });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={8} w="100%" align="center">
        <Stack
          isInline
          flexDir={['column', null, 'row']}
          spacing={[0, null, 16]}
          align={['center', null, 'flex-start']}
          w={['100%', null, '80%', '60%']}
        >
          <Box>
            <EditableAvatar picture={profile.picture} />
          </Box>
          <Stack spacing={8} align="center" w="80%">
            <FormControl
              isInvalid={
                formik.touched.fullName === true &&
                formik.errors.fullName !== undefined
              }
              w="100%"
            >
              <FormLabel htmlFor="fullName">Full Name</FormLabel>
              <EinsteinInput
                id="fullName"
                name="fullName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fullName}
              />
              <FormErrorMessage>
                {formik.touched.fullName && formik.errors.fullName}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                formik.touched.preferredName === true &&
                formik.errors.preferredName !== undefined
              }
              w="100%"
            >
              <FormLabel htmlFor="preferredName">Preferred Name</FormLabel>
              <EinsteinInput
                id="preferredName"
                name="preferredName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.preferredName}
              />
              <FormHelperText
                id="preferredName-helper-text"
                color="teal.500"
                opacity={0.36}
              >
                How we&apos;ll address you in emails.
              </FormHelperText>
              <FormErrorMessage>
                {formik.touched.preferredName && formik.errors.preferredName}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                formik.touched.primaryEmail === true &&
                formik.errors.primaryEmail !== undefined
              }
              w="100%"
            >
              <FormLabel htmlFor="primaryEmail">Primary Email</FormLabel>
              <EinsteinInput
                id="primaryEmail"
                name="primaryEmail"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.primaryEmail}
              />
              <FormErrorMessage>
                {formik.touched.primaryEmail && formik.errors.primaryEmail}
              </FormErrorMessage>
            </FormControl>
            <FormControl w="100%">
              <FormLabel htmlFor="alternateEmail">
                Alternate Email (optional)
              </FormLabel>
              <EinsteinInput
                id="alternateEmail"
                name="alternateEmail"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.alternateEmail}
              />
            </FormControl>
            <FormControl w="100%">
              <FormLabel htmlFor="phone">Phone</FormLabel>
              <EinsteinInput
                id="phone"
                name="phone"
                type="tel"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
              <FormErrorMessage>
                {formik.touched.phone && formik.errors.phone}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                formik.touched.timezone === true &&
                formik.errors.timezone !== undefined
              }
              w="100%"
            >
              <FormLabel htmlFor="timezone">Timezone</FormLabel>
              <EinsteinSelect
                id="timezone"
                name="timezone"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.timezone}
                color={
                  formik.values.timezone === ''
                    ? 'rgba(7, 68, 82, 0.3)'
                    : undefined
                }
              >
                <option value="" disabled hidden>
                  Timezone
                </option>
                {TIMEZONES.map((timezone) => (
                  <option key={timezone} value={timezone}>
                    {timezone}
                  </option>
                ))}
              </EinsteinSelect>
              <FormHelperText
                id="timezone-helper-text"
                color="teal.500"
                opacity={0.36}
              >
                Upon changing this field, all your current availabilities will
                be converted to the new timezone.
              </FormHelperText>
              <FormErrorMessage>
                {formik.touched.timezone && formik.errors.timezone}
              </FormErrorMessage>
            </FormControl>
            <FormControl w="100%">
              <FormLabel htmlFor="bio">Bio</FormLabel>
              <EinsteinTextarea
                id="bio"
                name="bio"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bio}
              />
              <FormHelperText
                id="bio-helper-text"
                color="teal.500"
                opacity={0.36}
              >
                Displayed when parents book availabilities.
              </FormHelperText>
            </FormControl>
          </Stack>
        </Stack>
        <Stack isInline spacing={4}>
          <ButtonGatsbyLink
            to="/teacher/home"
            size="lg"
            variant="outline"
            w="140px"
          >
            Done
          </ButtonGatsbyLink>
          <EinsteinButton
            size="lg"
            w="140px"
            type="submit"
            onClick={formik.handleSubmit}
            isLoading={formik.isSubmitting}
            loadingText="Saving..."
          >
            Save
          </EinsteinButton>
        </Stack>
      </Stack>
    </form>
  );
};

export default EditProfile;
