import React, { FC } from 'react';
import { QueryResult } from 'react-query';

import { Heading, PseudoBox, Stack } from '@chakra-ui/core';

import { TeacherClassFeedback } from 'types';
import FeedbackForm from './FeedbackForm';

// StudentFeedback component prop types
interface StudentFeedbackProps extends Pick<TeacherClassFeedback, 'students'> {
  classId: string;
  refetch: QueryResult<TeacherClassFeedback[], undefined>['refetch'];
}

/**
 * Wraps page for writing teacher feedback on a given class
 */
const StudentFeedback: FC<StudentFeedbackProps> = ({
  students,
  classId,
  refetch,
}: StudentFeedbackProps) => (
  <Stack
    w="100%"
    bg="white"
    rounded={8}
    m="0 auto"
    color="teal.500"
    border="1px solid"
    borderColor="gray.300"
    align="center"
    justify="center"
  >
    <Stack
      alignItems="center"
      justifyContent="center"
      p={4}
      borderBottom="1px solid"
      borderColor="gray.100"
      w="100%"
    >
      <Heading textTransform="uppercase" size="md" fontFamily="heading">
        Class Feedback
      </Heading>
    </Stack>
    <Stack w="100%">
      {students.map((student) => (
        <PseudoBox
          key={student.id}
          borderBottom="1px solid"
          borderColor="gray.100"
          _last={{ border: 'none' }}
        >
          <FeedbackForm {...student} classId={classId} refetch={refetch} />
        </PseudoBox>
      ))}
    </Stack>
  </Stack>
);

export default StudentFeedback;
