import React, { useCallback, useState } from 'react';
import type { FC } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';

import API from 'api';
import { LoadingSpinner } from 'components/_lib';
import useTeacher from 'hooks/useTeacher';
import type { Teacher } from 'types';

// AvatarUpload component prop types
interface AvatarUploadProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

/**
 * Modal with image drag-n-drop
 */
const AvatarUpload: FC<AvatarUploadProps> = ({
  isOpen,
  onClose,
}: AvatarUploadProps) => {
  const { refetch } = useTeacher()!;
  const toast = useToast();
  const [isDropping, setIsDropping] = useState<boolean>(false);

  const onDrop = useCallback(
    async (files: File[]): Promise<void> => {
      setIsDropping(true);
      const fd = new FormData();
      fd.append('image', files[0]);

      try {
        await API.put('/teachers/avatar', fd, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setIsDropping(false);
        toast({
          title: 'Success!',
          description: 'New avatar uploaded.',
          status: 'success',
          duration: 4500,
          isClosable: true,
        });
        refetch();
        onClose();
      } catch (error) {
        setIsDropping(false);
        toast({
          title: 'Error',
          description: 'Could not upload avatar at this time.',
          status: 'error',
          duration: 4500,
          isClosable: true,
        });
      }
    },
    [setIsDropping, refetch, onClose, toast]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDrop,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={['full', 'md']}
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent borderRadius={[0, 8]} color="teal.500" textAlign="center">
        <IconButton
          variant="ghost"
          variantColor="teal"
          aria-label="close"
          icon="close"
          w="min-content"
          alignSelf="flex-start"
          rounded={8}
          mt={2}
          ml={2}
          onClick={onClose}
        />
        <Stack p={8} pt={0} spacing={8}>
          <ModalHeader fontFamily="heading" textTransform="uppercase">
            Upload New Avatar
          </ModalHeader>
          <Flex
            h="150px"
            bg="gray.100"
            align="center"
            justify="center"
            color="gray.400"
            borderColor="gray.200"
            borderWidth="2px"
            borderStyle="dashed"
            p={4}
            rounded={4}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {isDropping ? (
              <LoadingSpinner />
            ) : isDragActive ? (
              <Text>Drop the file here...</Text>
            ) : (
              <Text>
                Drag &apos;n&apos; drop here, or click to select a file.
              </Text>
            )}
          </Flex>
        </Stack>
      </ModalContent>
    </Modal>
  );
};

// EditableAvatar component prop types
interface EditableAvatarProps {
  picture: Teacher['picture'];
}

/**
 * Avatar with optional edit circle icon button to pick image
 */
const EditableAvatar: FC<EditableAvatarProps> = ({
  picture,
}: EditableAvatarProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Box position="relative">
        <Avatar
          w={['8rem', '10rem']}
          h={['8rem', '10rem']}
          src={picture || undefined}
          showBorder
        />
        <IconButton
          aria-label="edit"
          icon="edit"
          variant="solid"
          position="absolute"
          rounded="100%"
          onClick={onOpen}
          size="sm"
          top={1}
          right={1}
        />
      </Box>
      <AvatarUpload isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default EditableAvatar;
