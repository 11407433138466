import React from 'react';
import type { FC } from 'react';

import { EinsteinRouter, Route } from 'components/_lib';
import { AuthProvider, TeacherProvider } from 'components/_providers';
import { Home, Profile, Schedule } from 'components/TeacherDashboard';

const DATA_URL = `${process.env.WEB_API_URL}/teachers/user`;
const LOGOUT_URL = `${process.env.WEB_API_URL}/teachers/user/logout`;

/**
 * Teacher dashboard client-only router, routes to:
 *   /teacher/home - Dashboard home
 */
const TeacherPage: FC = () => (
  <AuthProvider scope="teacher" dataUrl={DATA_URL} logoutUrl={LOGOUT_URL}>
    <TeacherProvider>
      <EinsteinRouter basepath="/teacher">
        <Route path="/home/*" component={Home} />
        <Route path="/profile" component={Profile} />
        <Route path="/schedule" component={Schedule} />
      </EinsteinRouter>
    </TeacherProvider>
  </AuthProvider>
);

export default TeacherPage;
