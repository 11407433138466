/*
 * === GENERIC ===
 */

export interface ErrorState {
  isActive: boolean;
  message: string;
}

export interface Address {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export enum DaysOfWeek {
  SUNDAY = 0,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export type Weekday =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';

export type ClassState = 'UPCOMING' | 'OCCURRING' | 'FINISHED' | 'CANCELLED';

/*
 * === API TYPES ===
 */

export interface Subject {
  id: string;
  name: 'Spanish' | 'Literacy';
  description: string;
  picture: string;
}

export interface Student {
  id: string;
  username: string;
  picture: string;
  level: number;
  stars: number;
}

export interface Teacher {
  id: string;
  fullName: string;
  preferredName: string;
  primaryEmail: string;
  alternateEmail: string | null;
  phone: string;
  picture: string | null;
  bio: string | null;
  rate: number;
  subject: Pick<Subject, 'id' | 'name' | 'picture'>;
  team: 'Einstein Studios' | 'PRIDE';
  timezone: string;
  createdAt: string;
  lastWebLogin: string;
  lastVrLogin: string;
}

export interface Plan {
  id: string;
  name: string;
  price: number;
  classesPerWeek: number;
  isOneOnOne: boolean;
  subject: string;
  team?: 'PRIDE';
  interval: 'day' | 'week' | 'month' | 'year';
  intervalCount: number;
}

export interface Coupon {
  id: string;
  name: string;
  amountOff: number;
  duration: string;
  valid: boolean;
}

export interface SubscriptionSchedulePhase {
  startDate: string;
  endDate: string;
  plan: Plan;
  coupon: Coupon | null;
}

export interface SubscriptionSchedule {
  id: string;
  status: 'not_started' | 'active' | 'completed' | 'released' | 'canceled';
  currentPhase: SubscriptionSchedulePhase;
  nextPhase: SubscriptionSchedulePhase;
  createdAt: string;
}

export interface Subscription {
  id: string;
  status:
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'active'
    | 'past_due'
    | 'canceled'
    | 'unpaid';
  customerId: string;
  plan: Plan;
  schedule: SubscriptionSchedule | null;
  coupon: Coupon | null;
  startedAt: string;
  renewsAt: string;
  cancelsAt: string | null;
}

export interface Parent {
  id: string;
  fullName: string;
  preferredName: string;
  primaryEmail: string;
  alternateEmail: string | null;
  phone: string | null;
  address: Address | null;
  referralLink: string;
  students: Student[];
  subjects: {
    [key in Subject['name']]: {
      team: 'Einstein Studios' | 'PRIDE';
      credits: number;
      bookings: Booking[];
      subscription?: Subscription;
    };
  };
}

export interface Availability {
  id: string;
  dayOfWeek: Weekday;
  startTime: string;
  durationInMinutes: number;
}

export interface RecurringAvailability extends Availability {
  firstClass: string;
}

export interface OneTimeAvailability
  extends Pick<Availability, 'id' | 'durationInMinutes'> {
  datetime: string;
}

export interface TrialAvailability
  extends Pick<Availability, 'id' | 'durationInMinutes'> {
  teacher: Pick<Teacher, 'id' | 'fullName' | 'picture' | 'timezone' | 'bio'>;
  datetime: string;
}

export interface Booking {
  id: string;
  studentId: string;
  availability: Availability;
  teacher: Pick<Teacher, 'id' | 'fullName' | 'picture' | 'timezone'>;
  subject: Pick<Subject, 'id' | 'name' | 'picture'>;
  isOneOnOne: boolean;
  createdAt: string;
}

export interface UpcomingStudentClass {
  id: string;
  state: ClassState;
  datetime: string;
  durationInMinutes: number;
  seats: number;
  level: number;
  isTrial: boolean;
  teacher: Pick<
    Teacher,
    | 'id'
    | 'fullName'
    | 'preferredName'
    | 'picture'
    | 'primaryEmail'
    | 'timezone'
  >;
  subject: Pick<Subject, 'id' | 'name' | 'picture'>;
}

export interface PastClass extends UpcomingStudentClass {
  wasAbsent: boolean | null;
  feedbackId: string | null;
}

// feedback type returned from getClassFeedback
export interface StudentClassFeedback {
  id: string;
  studentId: string;
  text: string;
  wasAbsent: boolean | null;
  createdAt: string;
  teacher: Pick<
    Teacher,
    'fullName' | 'preferredName' | 'picture' | 'primaryEmail' | 'phone'
  >;
  classId: string;
  datetime: string;
  durationInMinutes: string;
  isTrial: boolean;
  subject: Pick<Subject, 'name' | 'picture'>;
}

export interface UpcomingTeacherClass {
  id: string;
  state: ClassState;
  datetime: string;
  durationInMinutes: number;
  level: number;
  seats: number;
  isTrial: boolean;
  subject: Pick<Subject, 'id' | 'name' | 'picture'>;
  students: (Pick<Student, 'id' | 'username' | 'level' | 'picture'> & {
    parent: Pick<
      Parent,
      'fullName' | 'preferredName' | 'primaryEmail' | 'phone'
    >;
  })[];
}

export interface PastTeacherClass
  extends Omit<UpcomingTeacherClass, 'students'> {
  paidAt: string | null;
  students: (Pick<Student, 'id' | 'level' | 'picture' | 'username'> & {
    feedbackId: string | null;
  })[];
}

export interface TeacherClassFeedback {
  id: string;
  datetime: string;
  durationInMinutes: number;
  isTrial: boolean;
  paidAt: string | null;
  level: number;
  subject: Pick<Subject, 'id' | 'name' | 'picture'>;
  students: (Pick<Student, 'id' | 'username' | 'picture' | 'level'> & {
    feedback: {
      id: string;
      text: string | null;
      wasAbsent: boolean;
      createdAt: string;
    } | null;
  })[];
}
