import React, { useState } from 'react';
import type { FC } from 'react';
import type { WindowLocation } from '@reach/router';
import { useQuery } from 'react-query';
import type { QueryFunction } from 'react-query';
import { format, parseISO } from 'date-fns';

import {
  Avatar,
  Box,
  Icon,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/core';
import { FaCalendarPlus } from 'react-icons/fa';

import API from 'api';
import {
  ButtonGatsbyLink,
  EinsteinTable,
  IconButtonChakraLink,
  MobileSection,
  MobileSectionFooter,
  MobileSectionGroup,
  MobileSectionItem,
  MobileSectionTitle,
  PaginationButtons,
  Section,
  SectionBody,
  SectionFooter,
  SectionTitle,
} from 'components/_lib';
import type { UpcomingTeacherClass } from 'types';
import StudentsPopover from './StudentsPopover';

/*
 * === CONSTANTS ===
 */

// Number of classes to display per pagination
const SLICE_SIZE = 5;

// Table column titles
const TABLE_COLUMNS = ['Class', 'Students', 'Date & Time', 'Actions'];

// Default component rendered when zero upcoming classes are fetched.
const NO_CLASSES = (
  <Stack spacing={4} align="center">
    <Text fontWeight="bold">No upcoming classes.</Text>
  </Stack>
);

/*
 * === HELPERS ===
 */

/**
 * React Query function to retrieve teacher's upcoming classes
 */
const fetchUpcomingClasses: QueryFunction<
  UpcomingTeacherClass[],
  [string]
> = async () => {
  try {
    const response = await API.get<UpcomingTeacherClass[]>(
      `/teachers/classes`,
      { params: { type: 'upcoming' } }
    );
    return response.data;
  } catch {
    return [];
  }
};

/**
 * String formats the add-to-calendar link for a given class.
 *
 * @param student - Name of student attending class.
 * @param upcomingClass - Class to create link for.
 *
 * @returns - Formatted add-to-calendar link.
 */
const buildAddToCalendarLink = ({
  isTrial,
  subject,
  level,
  students,
  datetime,
  durationInMinutes,
}: Pick<
  UpcomingTeacherClass,
  | 'subject'
  | 'datetime'
  | 'durationInMinutes'
  | 'isTrial'
  | 'students'
  | 'level'
>): string => {
  const type = isTrial ? 'Assessment' : `${level} Class`;
  const title = `Einstein Studios ${subject.name} ${type}`;

  const description = [
    `Your upcoming ${subject.name} ${type.toLowerCase()}.\n\n`,
    'Student(s) - Parent(s):\n',
    ...students.map(
      ({ username, parent }) =>
        `${username} - ${parent.fullName} (${parent.primaryEmail})\n`
    ),
    '\nNeed to cancel this class? Let us know at albert@einsteinstudios.io.',
  ].join('');

  let link = `${process.env.WEB_PLATFORM_URL}/add-to-calendar`;
  link += `?title=${encodeURIComponent(title)}`;
  link += `&datetime=${datetime}`;
  link += `&duration_in_minutes=${durationInMinutes}`;
  link += `&description=${encodeURIComponent(description)}`;

  return link;
};

/**
 * Generates an array of data for an upcoming teacher class row in EinsteinTable.
 *
 * @param upcomingClass - Class to build table data for.
 *
 * @returns - Array of table data for given class.
 */
const buildUpcomingClassTableData = ({
  id,
  subject,
  isTrial,
  datetime,
  durationInMinutes,
  level,
  students,
}: UpcomingTeacherClass): any[] => [
  <Stack key={`${id}-class`} isInline align="center" justify="center">
    <Avatar size="sm" src={subject.picture} />
    <Stack spacing={0} textAlign="left">
      <Text fontWeight="bold">
        {isTrial ? 'Assessment' : `${subject.name} ${level}`}
      </Text>
      <Text d="flex" alignItems="center">
        <Icon name="time" mr={1} /> {durationInMinutes} min. class
      </Text>
    </Stack>
  </Stack>,
  <StudentsPopover students={students} />,
  format(parseISO(datetime), 'LLL d, p'),
  <Tooltip
    aria-label="add-to-calendar"
    label="Add to Calendar"
    placement="bottom"
    shouldWrapChildren
    pos="absolute"
    top={0}
    left={0}
  >
    <IconButtonChakraLink
      key={`${id}-add-to-calendar`}
      aria-label="add to calendar"
      variant="ghost"
      variantColor="teal"
      href={buildAddToCalendarLink({
        subject,
        isTrial,
        datetime,
        durationInMinutes,
        level,
        students,
      })}
      isExternal
      target="_blank"
      rel="noopener noreferrer"
      icon={FaCalendarPlus}
    />
  </Tooltip>,
];

/*
 * === COMPONENTS ===
 */

/**
 * Student upcoming class entry rendered on mobile
 */
const MobileUpcomingClass: FC<UpcomingTeacherClass> = ({
  subject,
  isTrial,
  durationInMinutes,
  level,
  datetime,
  students,
}: UpcomingTeacherClass) => (
  <MobileSectionItem d={['block', null, 'none']} _last={{ roundedBottom: 8 }}>
    <Stack isInline justifyContent="space-between" alignItems="center" w="100%">
      <Stack spacing={4} alignItems="center" isInline shouldWrapChildren>
        <Avatar size="sm" src={subject.picture} />
        <Box>
          <Text fontWeight="bold">
            {isTrial ? 'Assessment' : `${subject.name} ${level}`}
          </Text>
          <Text d="flex" alignItems="center">
            <Icon name="time" mr={1} /> {durationInMinutes} min. class
          </Text>
          <Text d="flex" alignItems="center">
            <Icon name="calendar" mr={1} />{' '}
            {format(parseISO(datetime), 'LLL d, p')}
          </Text>
        </Box>
      </Stack>
      <StudentsPopover
        students={students}
        addToCalendar={buildAddToCalendarLink({
          subject,
          isTrial,
          datetime,
          durationInMinutes,
          level,
          students,
        })}
      />
    </Stack>
  </MobileSectionItem>
);

// UpcomingTable component prop types
interface UpcomingTableProps {
  pathname: WindowLocation['pathname'];
}

/**
 * Teacher upcoming classes table
 */
const UpcomingTable: FC<UpcomingTableProps> = ({
  pathname,
}: UpcomingTableProps) => {
  const { data: upcomingClasses, status } = useQuery(
    'upcomingClasses',
    fetchUpcomingClasses
  );

  const [current, setCurrent] = useState<number>(0);

  const classSlice = upcomingClasses?.slice(
    current,
    Math.min(upcomingClasses.length, current + SLICE_SIZE)
  );

  const paginationButtons = (
    <PaginationButtons
      current={current}
      setCurrent={setCurrent}
      count={upcomingClasses?.length || 0}
      pageSize={SLICE_SIZE}
    />
  );

  return (
    <>
      {/* Mobile version */}
      <MobileSection>
        <MobileSectionTitle>Upcoming Classes</MobileSectionTitle>
        <Skeleton
          h={status === 'loading' ? '200px' : 'auto'}
          w="100%"
          roundedBottom={8}
          isLoaded={status !== 'loading'}
        >
          {upcomingClasses?.length === 0 || !upcomingClasses ? (
            <MobileSectionGroup p={8}>{NO_CLASSES}</MobileSectionGroup>
          ) : (
            <MobileSectionGroup>
              {classSlice?.map((upcomingClass) => (
                <MobileUpcomingClass
                  key={upcomingClass.id}
                  {...upcomingClass}
                />
              ))}
            </MobileSectionGroup>
          )}
          {(upcomingClasses?.length || 0) > SLICE_SIZE && (
            <MobileSectionFooter>
              {pathname === '/teacher/home' ? (
                <ButtonGatsbyLink
                  variant="link"
                  rightIcon="chevron-right"
                  variantColor="teal"
                  to="/teacher/home/upcoming"
                >
                  See More
                </ButtonGatsbyLink>
              ) : (
                paginationButtons
              )}
            </MobileSectionFooter>
          )}
        </Skeleton>
      </MobileSection>

      {/* Desktop version */}
      <Section>
        <SectionTitle>Upcoming Classes</SectionTitle>
        <Skeleton
          w="90%"
          d="flex"
          flexDir="column"
          alignItems="center"
          h={status === 'loading' ? '150px' : 'auto'}
          rounded={8}
          isLoaded={status !== 'loading'}
        >
          <SectionBody w="100%">
            {upcomingClasses?.length === 0 ? (
              NO_CLASSES
            ) : (
              <EinsteinTable
                columns={TABLE_COLUMNS}
                data={
                  classSlice?.map((upcomingClass) =>
                    buildUpcomingClassTableData(upcomingClass)
                  ) || []
                }
              />
            )}
          </SectionBody>
          {(upcomingClasses?.length || 0) > SLICE_SIZE && (
            <SectionFooter d="flex" mr="5%" alignSelf="flex-end">
              {pathname === '/teacher/home' ? (
                <ButtonGatsbyLink
                  variant="outline"
                  variantColor="orange"
                  rightIcon="chevron-right"
                  textTransform="uppercase"
                  fontFamily="heading"
                  rounded={8}
                  to="/teacher/home/upcoming"
                >
                  See More
                </ButtonGatsbyLink>
              ) : (
                paginationButtons
              )}
            </SectionFooter>
          )}
        </Skeleton>
      </Section>
    </>
  );
};

export default UpcomingTable;
