import React from 'react';
import type { FC } from 'react';

import { Box, Heading, Stack } from '@chakra-ui/core';

import { useTeacher } from 'hooks';
import EditProfileForm from './EditProfileForm';

/**
 * Form to edit parent profile details.
 */
const ProfileContainer: FC<{}> = () => {
  const { profile } = useTeacher()!;

  return (
    <Stack
      minH="100vh"
      color="teal.500"
      spacing={8}
      align="center"
      justify="center"
      px={[2, 4, 8, 16]}
      py={16}
    >
      <Heading fontFamily="heading" size="lg" textTransform="uppercase">
        Profile Modification
      </Heading>
      <Box w="100%">
        <EditProfileForm profile={profile} />
      </Box>
    </Stack>
  );
};

export default ProfileContainer;
