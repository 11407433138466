import React, { FC, ReactElement } from 'react';
import { useFormik } from 'formik';
import { QueryResult } from 'react-query';

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Icon,
  IconButton,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/core';
import styled from '@emotion/styled';
import { FaCalendarDay } from 'react-icons/fa';

import API from 'api';
import { EinsteinSelect, EinsteinButton } from 'components/_lib';
import colors from 'theme/colors';
import { Weekday } from 'types';

import { DAYS, TIMES, TeacherAvailability } from './types';

const SPANISH_AVAILABILITY_DURATION = 30;
const LITERACY_AVAILABILITY_DURATION = 60;

const StyledOption = styled.option`
  color: ${colors.teal['500']};
`;

// New class availability form field types
interface NewAvailabilityFormValues {
  dayOfWeek: Weekday | '';
  startTime: string;
}

const REQUIRED: (keyof NewAvailabilityFormValues)[] = [
  'dayOfWeek',
  'startTime',
];

// AvailabilitiesTable component prop types
interface NewAvailabilityModalProps {
  availabilities: TeacherAvailability[];
  refetch: QueryResult<TeacherAvailability[]>['refetch'];
  isOpen: boolean;
  onClose: VoidFunction;
  subject: string;
}

/**
 * Shipping information input form
 */
const NewAvailabilityModal: FC<NewAvailabilityModalProps> = ({
  availabilities,
  refetch,
  isOpen,
  onClose,
  subject,
}: NewAvailabilityModalProps) => {
  const durationInMinutes =
    subject === 'Spanish'
      ? SPANISH_AVAILABILITY_DURATION
      : LITERACY_AVAILABILITY_DURATION;

  const current = new Set(
    availabilities.map(
      ({ dayOfWeek, startTime }) => `${dayOfWeek}-${startTime}`
    )
  );

  const toast = useToast();

  // Form state and handlers
  const formik = useFormik<NewAvailabilityFormValues>({
    initialValues: {
      dayOfWeek: '',
      startTime: '',
    },
    validate: (values) =>
      REQUIRED.reduce(
        (p, c) => (!values[c] ? { ...p, [c]: 'Required' } : p),
        {}
      ),
    onSubmit: async ({ dayOfWeek, startTime }, { resetForm }) => {
      try {
        await API.post('/availabilities', {
          dayOfWeek,
          startTime,
          durationInMinutes,
        });
        resetForm();
        onClose();
        refetch();
        toast({
          title: 'Success!',
          status: 'success',
          description: 'Availability created',
          duration: 4500,
          isClosable: true,
        });
      } catch {
        toast({
          title: 'Failure',
          status: 'error',
          description: 'Could not create new availability at this time',
          duration: 4500,
          isClosable: true,
        });
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={['full', 'md']}
      isCentered
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent color="teal.500" rounded={[0, 8]}>
        <IconButton
          variant="ghost"
          w="min-content"
          alignSelf="flex-end"
          rounded={8}
          mt={2}
          mr={2}
          aria-label="close"
          icon="close"
          onClick={onClose}
        />
        <Box p={8} pt={2}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={8} w="100%" align="center">
              <Text
                fontWeight="bold"
                fontSize="lg"
                textTransform="uppercase"
                fontFamily="heading"
              >
                Add new class availability:
              </Text>
              <Stack spacing={8} align="center" w="100%">
                <FormControl
                  isInvalid={
                    formik.touched.dayOfWeek !== undefined &&
                    formik.errors.dayOfWeek !== undefined
                  }
                  w="100%"
                >
                  <InputGroup>
                    <InputLeftElement>
                      <Box as={FaCalendarDay} />
                    </InputLeftElement>
                    <EinsteinSelect
                      id="dayOfWeek"
                      name="dayOfWeek"
                      pl="2.5rem"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.dayOfWeek}
                      color={
                        formik.values.dayOfWeek === ''
                          ? 'rgba(7, 68, 82, 0.3)'
                          : undefined
                      }
                    >
                      <StyledOption value="" disabled hidden>
                        Day of week
                      </StyledOption>
                      {DAYS.map(
                        ({ code, string }): ReactElement => (
                          <StyledOption key={code} value={code}>
                            {string}
                          </StyledOption>
                        )
                      )}
                    </EinsteinSelect>
                  </InputGroup>
                  <FormHelperText
                    id="dayOfWeek-helper-text"
                    color="teal.500"
                    opacity={0.36}
                    textAlign="left"
                  >
                    Day of week that class will occur
                  </FormHelperText>
                  <FormErrorMessage>{formik.errors.dayOfWeek}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={
                    formik.touched.startTime !== undefined &&
                    formik.errors.startTime !== undefined
                  }
                  w="100%"
                >
                  <InputGroup>
                    <InputLeftElement>
                      <Icon name="time" />
                    </InputLeftElement>
                    <EinsteinSelect
                      id="startTime"
                      name="startTime"
                      pl="2.5rem"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.startTime}
                      color={
                        formik.values.startTime === ''
                          ? 'rgba(7, 68, 82, 0.3)'
                          : undefined
                      }
                    >
                      <StyledOption value="" disabled hidden>
                        Start time
                      </StyledOption>
                      {TIMES.filter(({ code }, index) =>
                        subject === 'Literacy'
                          ? index % 2 === 0 &&
                            !current.has(`${formik.values.dayOfWeek}-${code}`)
                          : !current.has(`${formik.values.dayOfWeek}-${code}`)
                      ).map(
                        ({ code, string }): ReactElement => (
                          <StyledOption key={code} value={code}>
                            {string}
                          </StyledOption>
                        )
                      )}
                    </EinsteinSelect>
                  </InputGroup>
                  <FormHelperText
                    id="startTime-helper-text"
                    color="teal.500"
                    opacity={0.36}
                    textAlign="left"
                  >
                    Time that class will start at ({durationInMinutes} min.
                    intervals)
                  </FormHelperText>
                  <FormErrorMessage>{formik.errors.startTime}</FormErrorMessage>
                </FormControl>
              </Stack>

              <EinsteinButton
                onClick={formik.handleSubmit}
                isLoading={formik.isSubmitting}
                loadingText="Adding..."
                size="lg"
                alignSelf="center"
                w="100%"
              >
                Add
              </EinsteinButton>
            </Stack>
          </form>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default NewAvailabilityModal;
