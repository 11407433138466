import React, { FC, useState } from 'react';
import { QueryResult } from 'react-query';
import { useFormik } from 'formik';

import {
  Avatar,
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/core';
import { FaRegCheckCircle } from 'react-icons/fa';

import API from 'api';
import { EinsteinButton, EinsteinTextarea } from 'components/_lib';
import { Student, TeacherClassFeedback } from 'types';

interface StudentFeedbackFormValues {
  text: string;
}

// FeedbackForm component prop types
interface StudentFeedbackProps
  extends Pick<Student, 'id' | 'username' | 'picture' | 'level'> {
  classId: string;
  feedback: {
    id: string;
    text: string | null;
    wasAbsent: boolean;
    createdAt: string;
  } | null;
  refetch: QueryResult<TeacherClassFeedback[], undefined>['refetch'];
}

/**
 * Individual student feedback form
 */
const FeedbackForm: FC<StudentFeedbackProps> = ({
  id: studentId,
  picture,
  username,
  feedback,
  classId,
  refetch,
}: StudentFeedbackProps) => {
  const toast = useToast();
  const [wasAbsent, setWasAbsent] = useState<boolean>(
    feedback?.wasAbsent === true
  );

  // Feedback input state and handlers
  const formik = useFormik<StudentFeedbackFormValues>({
    initialValues: { text: feedback?.text || '' },
    onSubmit: async ({ text }) => {
      try {
        await API.post(`/classes/${classId}/feedback`, {
          studentId,
          text: text.length > 0 ? text : undefined,
          wasAbsent,
        });
        refetch();
        toast({
          title: 'Success!',
          status: 'success',
          description: 'Feedback posted.',
          duration: 4500,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Failure',
          status: 'error',
          description: 'Could not post feedback at this time.',
          duration: 4500,
          isClosable: true,
        });
      }
    },
  });

  return (
    <form>
      <Flex
        flexDir={['column', null, 'row']}
        justify="space-around"
        align="center"
        w="100%"
        py={4}
        px={8}
      >
        <Stack spacing={4} align="center">
          <Stack isInline spacing={4} align="center">
            <Avatar size="lg" src={picture} />
            <Text fontWeight="bold">{username}</Text>
          </Stack>
          <Checkbox
            variantColor="orange"
            onChange={(): void => setWasAbsent(!wasAbsent)}
            isChecked={wasAbsent}
            isDisabled={feedback !== null}
          >
            Absent?
          </Checkbox>
        </Stack>
        <Stack spacing={4} w={['100%', null, '60%', '50%']} mt={[8, null, 0]}>
          <FormControl
            isInvalid={
              formik.touched.text !== undefined &&
              formik.errors.text !== undefined
            }
            w="100%"
          >
            <FormLabel htmlFor="text">Feedback</FormLabel>
            <EinsteinTextarea
              id="text"
              name="text"
              w="100%"
              isDisabled={feedback !== null}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.text}
            />
            <FormErrorMessage>{formik.errors.text}</FormErrorMessage>
          </FormControl>
          {feedback !== null ? (
            <Stack isInline spacing={2} align="center" justify="center">
              <Text
                fontWeight="bold"
                fontFamily="heading"
                textTransform="uppercase"
              >
                Submitted
              </Text>
              <Box
                as={FaRegCheckCircle}
                color="orange.500"
                w="1.25rem"
                h="1.25rem"
              />
            </Stack>
          ) : (
            <EinsteinButton
              onClick={formik.handleSubmit}
              isLoading={formik.isSubmitting}
              loadingText="Submitting..."
            >
              Submit
            </EinsteinButton>
          )}
        </Stack>
      </Flex>
    </form>
  );
};

export default FeedbackForm;
