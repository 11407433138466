import React, { FC, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';

import {
  BoxProps,
  Button,
  Divider,
  Flex,
  Icon,
  PseudoBox,
  Stack,
  Text,
} from '@chakra-ui/core';
import styled from '@emotion/styled';

import {
  MobileSectionGroup,
  MobileSectionItem,
  MobileSectionItemProps,
} from 'components/_lib';
import colors from 'theme/colors';
import { DaysOfWeek } from 'types';

import { DAYS, TeacherAvailability } from './types';

/**
 * CSS bubble
 */
const Bubble = styled.div`
  height: 20px;
  width: 20px;
  background: ${colors.teal['500']};
  border-radius: 100px;
`;

/**
 * Styled text for days of week names
 */
const DayOfWeekLabel: FC<BoxProps> = ({ children, ...rest }: BoxProps) => {
  return (
    <Text
      color="gray.500"
      fontFamily="heading"
      textTransform="uppercase"
      mb={1}
      {...rest}
    >
      {children}
    </Text>
  );
};

// MobileAvailabilitiesCalendar component prop types
interface MobileAvailabilitiesCalendarProps {
  availabilities: TeacherAvailability[];
  subject: string;
  newAvailabilityFormOnOpen: VoidFunction;
  handleSelect: (availabilityId: TeacherAvailability) => void;
}

// AvailabilityComponent component prop types
interface AvailabilityComponentProps extends MobileSectionItemProps {
  availability: TeacherAvailability;
  subject: string;
  handleSelect: MobileAvailabilitiesCalendarProps['handleSelect'];
}

/**
 * Mobile element for a given availability
 */
const AvailabilityComponent: FC<AvailabilityComponentProps> = ({
  availability,
  handleSelect,
  subject,
  ...rest
}: AvailabilityComponentProps) => {
  const name = availability.booking
    ? `${subject} ${availability.booking!.level}`
    : 'Open';
  const time = format(
    parseISO(`${format(Date.now(), 'yyyy-MM-dd')} ${availability.startTime}`),
    'p'
  );

  return (
    <MobileSectionItem
      p={4}
      _hover={{ bg: 'gray.100' }}
      _active={{ bg: 'gray.200' }}
      bg="white"
      cursor="pointer"
      w="100%"
      onClick={(): void => handleSelect(availability)}
      {...rest}
    >
      <Stack isInline spacing={8}>
        <Text color="orange.500" w="70px">
          {time}
        </Text>
        <Text fontWeight="bold">{name}</Text>
      </Stack>
      <Icon name="info-outline" />
    </MobileSectionItem>
  );
};

/**
 * Week calendar rendering authenticated teacher's availabilities schedule, mobile version
 */
const MobileAvailabilitiesCalendar: FC<MobileAvailabilitiesCalendarProps> = ({
  availabilities,
  newAvailabilityFormOnOpen,
  handleSelect,
  subject,
}: MobileAvailabilitiesCalendarProps) => {
  const [sortedAvailabilities, setSortedAvailabilities] = useState<
    TeacherAvailability[][]
  >([[], [], [], [], [], [], []]);

  useMemo(() => {
    setSortedAvailabilities(
      availabilities.reduce(
        (p: TeacherAvailability[][], c) => {
          p[DaysOfWeek[c.dayOfWeek]].push(c);
          return p;
        },
        [[], [], [], [], [], [], []]
      )
    );
  }, [availabilities]);

  return (
    <>
      <Button
        pos="absolute"
        left={0}
        right={0}
        top={['82px', '84px', '86px', '90px']}
        size="lg"
        variantColor="orange"
        rounded={0}
        w="100%"
        onClick={newAvailabilityFormOnOpen}
        fontFamily="heading"
        textTransform="uppercase"
        rightIcon="add"
      >
        New class
      </Button>

      <Stack spacing={8}>
        {DAYS.map((day, index) =>
          sortedAvailabilities[index].length > 0 ? (
            <PseudoBox key={day.code} _first={{ mt: '3rem' }}>
              <DayOfWeekLabel>{day.string}</DayOfWeekLabel>
              <MobileSectionGroup w="100%" rounded={8} color="teal.500">
                {sortedAvailabilities[index].map((a, i) => {
                  const isFirst = i === 0;
                  const isLast = i === sortedAvailabilities[index].length - 1;
                  const isMiddle = !isFirst && !isLast;

                  return (
                    <Stack key={a.id} isInline spacing={2} align="center">
                      <Flex w="25px" align="center" justify="center">
                        {isMiddle ? (
                          <Divider
                            orientation="vertical"
                            borderColor="teal.500"
                            h="100px"
                            mt="-50px"
                            mb="-50px"
                          />
                        ) : (
                          <Flex flexDir="column" align="center">
                            <Bubble />
                            {isFirst &&
                              sortedAvailabilities[index].length === 2 && (
                                <Divider
                                  orientation="vertical"
                                  borderColor="teal.500"
                                  h="50px"
                                  mb="-50px"
                                />
                              )}
                          </Flex>
                        )}
                      </Flex>
                      <AvailabilityComponent
                        subject={subject}
                        borderTopLeftRadius={isFirst ? 8 : undefined}
                        borderTopRightRadius={isFirst ? 8 : undefined}
                        borderBottomLeftRadius={isLast ? 8 : undefined}
                        borderBottomRightRadius={isLast ? 8 : undefined}
                        handleSelect={handleSelect}
                        availability={a}
                      />
                    </Stack>
                  );
                })}
              </MobileSectionGroup>
            </PseudoBox>
          ) : null
        )}
      </Stack>
    </>
  );
};

export default MobileAvailabilitiesCalendar;
