import React, { useState } from 'react';
import type { FC } from 'react';
import type { QueryResult } from 'react-query';
import { format, parseISO } from 'date-fns';

import {
  Avatar,
  Box,
  Divider,
  Heading,
  IconButton,
  List,
  ListItem,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/core';
import { FaEnvelope, FaLandmark, FaPhone, FaUser } from 'react-icons/fa';

import API from 'api';
import { EinsteinButton } from 'components/_lib';
import type { Subject } from 'types';
import { TIMES, TeacherAvailability } from './types';

// AvailabilityModal component prop types
interface AvailabilityModalProps {
  availability: TeacherAvailability;
  refetch: QueryResult<TeacherAvailability[]>['refetch'];
  isOpen: boolean;
  onClose: VoidFunction;
  subject: Pick<Subject, 'name' | 'picture'>;
}

/**
 * Detail/action modal for a given availability
 */
const AvailabilityModal: FC<AvailabilityModalProps> = ({
  availability,
  isOpen,
  refetch,
  onClose,
  subject,
}: AvailabilityModalProps) => {
  const name = availability.booking
    ? `${subject.name} ${availability.booking.level}`
    : 'Open';

  const notBooked = !availability.booking && !availability.classes;

  const toast = useToast();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const handleDelete = async (): Promise<void> => {
    setIsDeleting(true);
    try {
      await API.delete(`/availabilities/${availability.id}`);
      setIsDeleting(false);
      onClose();
      refetch();
      toast({
        title: 'Success',
        status: 'success',
        description: 'Availability deleted',
        duration: 4500,
        isClosable: true,
      });
    } catch {
      setIsDeleting(false);
      toast({
        title: 'Failure',
        status: 'error',
        description: 'Could not delete availability at this time',
        duration: 4500,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={['full', 'lg']}
      isCentered
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent color="teal.500" rounded={[0, 8]}>
        <IconButton
          variant="ghost"
          w="min-content"
          alignSelf="flex-end"
          rounded={8}
          mt={2}
          mr={2}
          aria-label="close"
          icon="close"
          onClick={onClose}
        />
        <Stack spacing={8} align="center" p={8} pt={2}>
          <Heading
            size="md"
            fontFamily="heading"
            textTransform="uppercase"
            textAlign="center"
          >
            {availability.dayOfWeek}{' '}
            {TIMES.find((t) => t.code === availability.startTime)!.string}
          </Heading>
          {notBooked ? (
            <Text>Not booked.</Text>
          ) : availability.type === 'recurring' ? (
            <Stack spacing={8} align="center" w="100%">
              <Stack spacing={8} isInline>
                <Stack isInline align="center">
                  <Box as={FaLandmark} />
                  <Text>{name}</Text>
                </Stack>
                <Stack isInline align="center">
                  <Box as={FaUser} />
                  <Text>{`${availability.booking!.students?.length} ${
                    availability.booking!.students?.length === 1
                      ? 'student'
                      : 'students'
                  }`}</Text>
                </Stack>
              </Stack>
              <Divider borderColor="gray.500" w="100%" h="1px" />
              <List
                spacing={8}
                textAlign="left"
                alignSelf="flex-start"
                w="100%"
              >
                {availability.booking?.students.map((s) => (
                  <ListItem key={s.id}>
                    <Stack isInline spacing={2} align="center">
                      <Avatar size="lg" src={s.picture} />
                      <Stack spacing={0}>
                        <Text fontWeight="bold">{s.username}</Text>
                        <Text>Parent: {s.parent.fullName}</Text>
                        <Text d="inline-flex" alignItems="center">
                          <Box as={FaEnvelope} mr={2} /> {s.parent.primaryEmail}
                        </Text>
                        {s.parent.phone && (
                          <Text d="inline-flex" alignItems="center">
                            <Box as={FaPhone} mr={2} /> {s.parent.phone}
                          </Text>
                        )}
                      </Stack>
                    </Stack>
                  </ListItem>
                ))}
              </List>
            </Stack>
          ) : (
            <Stack spacing={8} w="100%">
              <Stack spacing={8} isInline>
                <Stack isInline>
                  <Text>Upcoming one-time classes:</Text>
                </Stack>
              </Stack>
              <List spacing={8} w="100%">
                {availability.classes!.map((c) => (
                  <ListItem key={c.id}>
                    <Stack isInline spacing={2} align="center">
                      <Avatar size="md" src={subject.picture} />
                      <Stack spacing={0}>
                        <Text fontWeight="bold">{`${subject.name} ${
                          availability.classes![0].level
                        }`}</Text>
                        <Text>{format(parseISO(c.datetime), 'ccc, P, p')}</Text>
                      </Stack>
                    </Stack>
                  </ListItem>
                ))}
              </List>
            </Stack>
          )}

          {notBooked && (
            <EinsteinButton
              onClick={handleDelete}
              isLoading={isDeleting}
              loadingText="Deleting..."
              size="lg"
              variantColor="red"
              variant="outline"
            >
              Delete Availability
            </EinsteinButton>
          )}
        </Stack>
      </ModalContent>
    </Modal>
  );
};

export default AvailabilityModal;
