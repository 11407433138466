import React from 'react';
import type { FC } from 'react';
import { useLocation } from '@reach/router';

import { withLayout, withSEO } from 'components/_hoc';
import { BreadcrumbNav } from 'components/_lib';

import UpcomingTable from './UpcomingTable';

/**
 * Wraps parent complete class history page
 */
const UpcomingPage: FC<{}> = () => {
  const { pathname } = useLocation();

  return (
    <>
      <BreadcrumbNav
        links={[
          { href: '/teacher/home', text: 'Home', isCurrentPage: false },
          {
            href: pathname,
            text: 'Upcoming',
            isCurrentPage: true,
          },
        ]}
      />
      <UpcomingTable pathname={pathname} />
    </>
  );
};

export default withSEO(withLayout(UpcomingPage), {
  title: 'Upcoming • Teacher',
  description: 'Teacher upcoming class schedule.',
  pathname: '/teacher/home/upcoming',
});
