import React, { createRef, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';

import {
  Box,
  Checkbox,
  Flex,
  Grid,
  Heading,
  PseudoBox,
  Stack,
} from '@chakra-ui/core';
import styled from '@emotion/styled';

import { EinsteinButton } from 'components/_lib';
import colors from 'theme/colors';
import { DaysOfWeek, Subject } from 'types';

import { TIMES, TeacherAvailability } from './types';

const StyledTable = styled.table`
  border-collapse: collapse;
  flex-basis: 100%;
  flex-grow: 0;
  table-layout: fixed;
  width: 100%;
`;

const StyledTH = styled.th`
  color: ${colors.teal['500']};
  font-size: 16px;
  font-weight: bold;
  padding: 2rem 1rem 2rem 1rem;
  text-align: center;

  &.head {
    width: 60px;
  }
`;

const StyledTR = styled.tr(
  ({ height }: any) => `
  &:nth-of-type(odd) td:not(.head) {
    border-bottom: 1px solid ${colors.gray[height === '60px' ? '600' : '300']};
  }

  &:nth-of-type(even):not(:last-child) td:not(.head) {
    border-bottom: 1px solid ${colors.gray['500']};
  }

  & td {
    height: ${height};
    padding: 0;
    white-space: none;
    word-wrap: nowrap;
  }

  .head {
    padding: 0.25rem 0.5rem 0;
    text-align: right;
    top: -12.5px;
    width: 60px;
  }
`
);

const StyledTD = styled.td(
  ({ height }: any) => `
  font-size: 12px;
  text-align: right;

  font-size: 12px;
  position: relative;
  vertical-align: top;
  height: ${height};
  padding: 0.25rem 0.25rem 0 0.25rem;

  &:not(:first-of-type):not(:last-child) {
    border-right: 1px solid ${colors.gray['500']};
  }
`
);

const StyledBox = styled(Box)`
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

// AvailabilitiesCalendar component prop types
interface AvailabilitiesCalendarProps {
  availabilities: TeacherAvailability[];
  newAvailabilityModalOnOpen: VoidFunction;
  handleSelect: (availabilityId: TeacherAvailability) => void;
  subject: Pick<Subject, 'name' | 'picture'>;
}

// AvailabilityComponent component prop types
interface AvailabilityComponentProps
  extends Pick<AvailabilitiesCalendarProps, 'handleSelect'> {
  availability: TeacherAvailability;
  subject: Pick<Subject, 'name' | 'picture'>;
}

/**
 * Calendar element for a given availability
 */
const AvailabilityComponent: FC<AvailabilityComponentProps> = ({
  availability,
  handleSelect,
  subject,
}: AvailabilityComponentProps) => {
  const name = availability.booking
    ? `${subject.name} ${availability.booking.level}`
    : 'Open';

  return (
    <StyledTD>
      <PseudoBox
        d="flex"
        bg={
          name.endsWith('1')
            ? 'teal.500'
            : name.endsWith('2')
            ? 'orange.500'
            : name.endsWith('3')
            ? 'green.500'
            : 'gray.500'
        }
        color="white"
        p={1}
        alignItems="center"
        justifyContent="center"
        zIndex={2}
        transition="all .2s ease-in-out"
        border="1px solid transparent"
        cursor="pointer"
        onClick={(): void => handleSelect(availability)}
        rounded={2}
        h={subject.name === 'Spanish' ? '30px' : '60px'}
        _hover={{
          boxShadow: 'md',
          transform: 'scale(1.05)',
        }}
      >
        {name}
      </PseudoBox>
    </StyledTD>
  );
};

type FilteredAvailabilities = {
  [key: string]: TeacherAvailability;
}[];

type AvailabilityFilters = { [key: string]: boolean };

/**
 * Week calendar rendering authenticated teacher's availabilities schedule
 */
const AvailabilitiesCalendar: FC<AvailabilitiesCalendarProps> = ({
  availabilities,
  newAvailabilityModalOnOpen,
  handleSelect,
  subject,
}: AvailabilitiesCalendarProps) => {
  const [filteredAvailabilities, setFilteredAvailabilities] = useState<
    FilteredAvailabilities
  >([]);
  const [filters, setFilters] = useState<AvailabilityFilters>({});

  const [initialAvailabilities, initialFilters] = useMemo(() => {
    const a: FilteredAvailabilities = [{}, {}, {}, {}, {}, {}, {}];
    const set = new Set<string>([]);

    availabilities.forEach((v) => {
      a[DaysOfWeek[v.dayOfWeek]][v.startTime] = v;
      set.add(v.booking ? `${subject.name} ${v.booking.level}` : 'Open');
    });

    const subjects: string[] = [];
    set.forEach((name) => subjects.push(name));
    const f: AvailabilityFilters = subjects
      .sort()
      .reduce((p, c) => ({ ...p, [c]: false }), {});

    return [a, f];
  }, [availabilities, subject.name]);

  useMemo(() => {
    setFilteredAvailabilities(initialAvailabilities);
    setFilters(initialFilters);
  }, [
    setFilteredAvailabilities,
    initialAvailabilities,
    setFilters,
    initialFilters,
  ]);

  const toggleFilter = (name: string): void => {
    const newFilters = { ...filters, [name]: !filters[name] };
    const noneChecked =
      Object.values(newFilters).find((f) => f === true) === undefined;

    setFilters(newFilters);
    setFilteredAvailabilities(
      noneChecked
        ? initialAvailabilities
        : initialAvailabilities.map((day) =>
            Object.keys(day).reduce((p, c) => {
              const availability = day[c];
              const n = availability.booking
                ? `${subject.name} ${availability.booking!.level}`
                : 'Open';
              return newFilters[n] ? { ...p, [c]: day[c] } : p;
            }, {})
          )
    );
  };

  const tableRef = createRef<HTMLDivElement>();
  useEffect((): void => {
    tableRef!.current!.scrollTop = 500;
  }, [tableRef]);

  return (
    <>
      <Heading size="md" textTransform="uppercase" color="teal.500" mb={4}>
        Your Availabilities
      </Heading>
      <Grid
        margin="0 auto"
        w="100%"
        border="1px solid"
        borderColor="gray.500"
        backgroundColor="white"
        rounded={8}
        color="teal.500"
        gridTemplateColumns={{ md: '100%', lg: '20% 80%' }}
        gridTemplateRows={{ md: '15% 85%', lg: '100%' }}
        boxShadow="lg"
      >
        <Flex
          p={8}
          pr={{ lg: 0 }}
          flexDir={{ md: 'row-reverse', lg: 'column' }}
          align="center"
          justify={{ md: 'space-between', lg: 'flex-start' }}
        >
          <EinsteinButton
            size="lg"
            onClick={newAvailabilityModalOnOpen}
            mb={{ md: 0, lg: 16 }}
            w={{ md: 'auto', lg: '100%' }}
            rightIcon="add"
          >
            New Class
          </EinsteinButton>
          <Stack spacing={4} w={{ md: 'auto', lg: '100%' }}>
            <Heading
              size="md"
              textTransform="uppercase"
              textAlign={{ md: 'left', lg: 'center' }}
            >
              Filters
            </Heading>
            <Flex textAlign="left" flexDir={{ md: 'row', lg: 'column' }}>
              {Object.keys(filters).map((filter) => (
                <Checkbox
                  key={filter}
                  value={filter}
                  isChecked={filters[filter]}
                  onChange={(): void => toggleFilter(filter)}
                  mx={{ md: 2 }}
                  my={{ lg: 2 }}
                >
                  {filter}
                </Checkbox>
              ))}
            </Flex>
          </Stack>
        </Flex>

        <Flex w="100%" h="100%" pr={8} flexDir="column">
          <StyledTable>
            <thead>
              <tr>
                <StyledTH className="head" />
                <StyledTH>sun.</StyledTH>
                <StyledTH>mon.</StyledTH>
                <StyledTH>tues.</StyledTH>
                <StyledTH>wed.</StyledTH>
                <StyledTH>thurs.</StyledTH>
                <StyledTH>fri.</StyledTH>
                <StyledTH>sat.</StyledTH>
              </tr>
            </thead>
          </StyledTable>

          <StyledBox
            overflowX="hidden"
            overflowY="scroll"
            maxH="500px"
            minH="500px"
            py={2}
            flexBasis="100%"
            flexGrow={0}
            ref={tableRef}
          >
            <StyledTable>
              <tbody>
                {TIMES.filter((_, i) =>
                  subject.name === 'Spanish' ? true : i % 2 === 0
                ).map(({ label, code }) => (
                  <StyledTR
                    key={code}
                    height={subject.name === 'Spanish' ? '30px' : '60px'}
                  >
                    <StyledTD className="head">{label}</StyledTD>
                    {[0, 1, 2, 3, 4, 5, 6].map((index) => {
                      const availability =
                        filteredAvailabilities[index] &&
                        filteredAvailabilities[index][code];

                      return availability ? (
                        <AvailabilityComponent
                          key={index}
                          subject={subject}
                          availability={availability}
                          handleSelect={handleSelect}
                        />
                      ) : (
                        <StyledTD
                          key={index}
                          height={subject.name === 'Spanish' ? '30px' : '60px'}
                        />
                      );
                    })}
                  </StyledTR>
                ))}
              </tbody>
            </StyledTable>
          </StyledBox>
        </Flex>
      </Grid>
    </>
  );
};

export default AvailabilitiesCalendar;
