import React, { FC } from 'react';
import { useLocation } from '@reach/router';

import { Stack } from '@chakra-ui/core';

import { withLayout, withSEO, withSpacing } from 'components/_hoc';

import HistoryTable from './HistoryTable';
import UpcomingTable from './UpcomingTable';

/**
 * Wraps parent root home page
 */
const RootPage: FC<{}> = () => {
  const { pathname } = useLocation();

  return (
    <Stack spacing={16} shouldWrapChildren>
      <UpcomingTable pathname={pathname} />
      <HistoryTable pathname={pathname} />
    </Stack>
  );
};

export default withSEO(withLayout(withSpacing(RootPage)), {
  title: 'Home • Teacher',
  description:
    'Teacher home dashboard for Einstein Studios. View upcoming classes and class history',
  pathname: '/teacher/home',
});
