import React, { useState } from 'react';
import type { FC, ReactElement } from 'react';
import { WindowLocation } from '@reach/router';
import { useQuery } from 'react-query';
import type { QueryFunction } from 'react-query';
import { format, parseISO } from 'date-fns';

import { Avatar, Box, Skeleton, Stack, Text } from '@chakra-ui/core';
import { FaCheckCircle, FaFileInvoiceDollar, FaRegEdit } from 'react-icons/fa';

import API from 'api';
import {
  ButtonGatsbyLink,
  ButtonGatsbyLinkProps,
  EinsteinTable,
  MobileSection,
  MobileSectionTitle,
  MobileSectionFooter,
  MobileSectionGroup,
  MobileSectionItem,
  PaginationButtons,
  Section,
  SectionBody,
  SectionFooter,
  SectionTitle,
} from 'components/_lib';
import { PastTeacherClass } from 'types';
import StudentsPopover from './StudentsPopover';

/*
 * === CONSTANTS ===
 */

// Number of classes to display per pagination
const SLICE_SIZE = 5;

// Table column titles
const TABLE_COLUMNS = ['Class', 'Students', 'Date & Time', 'Feedback'];

// Enumeration of possible finished class feedback statuses
enum FeedbackStatus {
  pending,
  written,
  paid,
}

// Default component rendered when zero finished classes are fetched.
const NO_CLASSES = (
  <Stack spacing={4} align="center">
    <Text fontWeight="bold">No past classes.</Text>
  </Stack>
);

/*
 * === HELPERS ===
 */

/**
 * React Query function to fetch teacher finished classes.
 */
const fetchFinishedClasses: QueryFunction<
  PastTeacherClass[],
  [string, { limit?: number }]
> = async (_, { limit }) => {
  try {
    const response = await API.get<PastTeacherClass[]>(`/teachers/classes`, {
      params: { limit, type: 'past' },
    });
    return response.data;
  } catch {
    return [];
  }
};

/**
 * Determines if a finished class entry can be considered "complete"
 *
 * @param students - Student list of past class entry to validate.
 *
 * @returns - Boolean indidicating if class required no more feedback.
 */
const allClassFeedbackIsWritten = (
  students: PastTeacherClass['students']
): boolean =>
  students.filter((student) => student.feedbackId !== null).length ===
  students.length;

/**
 * Generates an array of data for a finished teacher class row in EinsteinTable.
 *
 * @param finishedClass - Class to build table data for.
 *
 * @returns - Array of table data for given class.
 */
const buildFinishedClassTableData = ({
  id,
  subject,
  isTrial,
  datetime,
  level,
  students,
  paidAt,
}: PastTeacherClass): (ReactElement | string)[] => [
  <Stack isInline align="center" justify="center">
    <Avatar size="sm" src={subject.picture} />
    <Text fontWeight="bold">
      {isTrial ? 'Assessment' : `${subject.name} ${level}`}
    </Text>
  </Stack>,
  <StudentsPopover students={students} />,
  format(parseISO(datetime), 'LLL d, p'),
  <FeedbackButton
    status={
      paidAt !== null
        ? FeedbackStatus.paid
        : allClassFeedbackIsWritten(students)
        ? FeedbackStatus.written
        : FeedbackStatus.pending
    }
    key={`${id}-feedback`}
    to={`/teacher/home/history/${id}`}
  />,
];

/*
 * === COMPONENTS ===
 */

// FeedbackButton component prop types
interface FeedbackButtonProps extends Omit<ButtonGatsbyLinkProps, 'children'> {
  status: FeedbackStatus;
}

/**
 * Generic styled button to view class feedback
 */
const FeedbackButton: FC<FeedbackButtonProps> = ({
  status,
  ...rest
}: FeedbackButtonProps) => (
  <ButtonGatsbyLink
    variant={status === FeedbackStatus.pending ? 'solid' : 'ghost'}
    variantColor={
      status === FeedbackStatus.pending
        ? 'orange'
        : status === FeedbackStatus.written
        ? 'teal'
        : 'green'
    }
    textTransform="uppercase"
    fontFamily="heading"
    size="sm"
    rounded={8}
    rightIcon={() => (
      <Box
        as={
          status === FeedbackStatus.pending
            ? FaRegEdit
            : status === FeedbackStatus.written
            ? FaCheckCircle
            : FaFileInvoiceDollar
        }
        ml={1}
      />
    )}
    {...rest}
  >
    {status === FeedbackStatus.pending
      ? 'Pending'
      : status === FeedbackStatus.written
      ? 'Done'
      : 'Paid'}
  </ButtonGatsbyLink>
);

/**
 * Student finished class entry rendered on mobile
 */
const MobileFinishedClass: FC<PastTeacherClass> = ({
  id,
  subject,
  isTrial,
  level,
  datetime,
  students,
  paidAt,
}: PastTeacherClass) => (
  <MobileSectionItem>
    <Stack spacing={4} alignItems="center" isInline shouldWrapChildren>
      <Avatar size="sm" src={subject.picture} />
      <Box>
        <Text fontWeight="bold">
          {isTrial ? 'Assessment' : `${subject.name} ${level}`}
        </Text>
        <Text w="140px">{format(parseISO(datetime), 'LLL d, p')}</Text>
      </Box>
    </Stack>
    <FeedbackButton
      status={
        paidAt !== null
          ? FeedbackStatus.paid
          : allClassFeedbackIsWritten(students)
          ? FeedbackStatus.written
          : FeedbackStatus.pending
      }
      to={`/teacher/home/history/${id}`}
    />
  </MobileSectionItem>
);

// HistoryTable component prop types
interface HistoryTableProps {
  pathname: WindowLocation['pathname'];
  limit?: number;
}

/**
 * Parent class history table
 */
const HistoryTable: FC<HistoryTableProps> = ({
  pathname,
  limit,
}: HistoryTableProps) => {
  const { data: pastClasses, status } = useQuery(
    ['pastClasses', { limit }],
    fetchFinishedClasses
  );

  const [current, setCurrent] = useState<number>(0);

  const classSlice = pastClasses?.slice(
    current,
    Math.min(pastClasses.length, current + SLICE_SIZE)
  );

  const paginationButtons = (
    <PaginationButtons
      current={current}
      setCurrent={setCurrent}
      count={!pastClasses ? 0 : pastClasses.length}
      pageSize={SLICE_SIZE}
    />
  );

  return (
    <>
      {/* Mobile version */}
      <MobileSection>
        <MobileSectionTitle
          backTo={pathname !== '/teacher/home' ? '/teacher/home' : undefined}
        >
          History
        </MobileSectionTitle>
        <Skeleton
          h={status === 'loading' ? '200px' : 'auto'}
          w="100%"
          roundedBottom={8}
          isLoaded={status !== 'loading'}
        >
          {pastClasses?.length === 0 || !pastClasses ? (
            <MobileSectionGroup p={8}>{NO_CLASSES}</MobileSectionGroup>
          ) : (
            <MobileSectionGroup>
              {classSlice?.map((finishedClass) => (
                <MobileFinishedClass
                  key={finishedClass.id}
                  {...finishedClass}
                />
              ))}
            </MobileSectionGroup>
          )}
          <MobileSectionFooter>
            {pathname === '/teacher/home' &&
            (pastClasses?.length || 0) > SLICE_SIZE ? (
              <ButtonGatsbyLink
                variant="link"
                rightIcon="chevron-right"
                variantColor="teal"
                to="/teacher/home/history"
              >
                See More
              </ButtonGatsbyLink>
            ) : (
              paginationButtons
            )}
          </MobileSectionFooter>
        </Skeleton>
      </MobileSection>

      {/* Desktop version */}
      <Section>
        <SectionTitle>History</SectionTitle>
        <Skeleton
          w="90%"
          d="flex"
          flexDir="column"
          alignItems="center"
          h={status === 'loading' ? '150px' : 'auto'}
          rounded={8}
          isLoaded={status !== 'loading'}
        >
          <SectionBody w="100%">
            {!classSlice || classSlice.length === 0 ? (
              NO_CLASSES
            ) : (
              <EinsteinTable
                columns={TABLE_COLUMNS}
                data={
                  classSlice.map((finishedClass) =>
                    buildFinishedClassTableData(finishedClass)
                  ) || []
                }
              />
            )}
          </SectionBody>
          {(pastClasses?.length || 0) > SLICE_SIZE && (
            <SectionFooter d="flex" mr="5%" alignSelf="flex-end">
              {pathname === '/teacher/home' ? (
                <ButtonGatsbyLink
                  variant="outline"
                  variantColor="orange"
                  rightIcon="chevron-right"
                  textTransform="uppercase"
                  fontFamily="heading"
                  rounded={8}
                  to="/teacher/home/history"
                >
                  See More
                </ButtonGatsbyLink>
              ) : (
                paginationButtons
              )}
            </SectionFooter>
          )}
        </Skeleton>
      </Section>
    </>
  );
};

export default HistoryTable;
