import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';

import { Box, Flex, useDisclosure } from '@chakra-ui/core';

import API from 'api';
import { LoadingSpinner } from 'components/_lib';
import { useTeacher } from 'hooks';

import { TeacherAvailability } from './types';
import AvailabilitiesCalendar from './AvailabilitiesCalendar';
import MobileAvailabilitiesCalendar from './MobileAvailabilitiesCalendar';
import AvailabilityFormModal from './NewAvailabilityModal';
import AvailabilityModal from './AvailabilityModal';

/**
 * Lays out teacher scheduling page
 */
const ScheduleContainer: FC = () => {
  const {
    profile: { subject },
  } = useTeacher()!;

  const { data: availabilities, status, refetch } = useQuery(
    'availabilities',
    (): Promise<TeacherAvailability[]> =>
      (async (): Promise<TeacherAvailability[]> => {
        try {
          const response = await API.get(`/teachers/availabilities`);
          return response.data;
        } catch {
          return [];
        }
      })()
  );

  const newAvailabilityModalHandlers = useDisclosure();
  const availabilityModalHandlers = useDisclosure();

  const [selectedAvailability, setSelectedAvailability] = useState<
    TeacherAvailability
  >();

  const handleSelect = (availability: TeacherAvailability): void => {
    setSelectedAvailability(availability);
    availabilityModalHandlers.onOpen();
  };

  return status === 'loading' || !availabilities ? (
    <Flex minH="inherit" align="center" justify="center">
      <LoadingSpinner />
    </Flex>
  ) : (
    <>
      <Box d={['none', null, 'block']}>
        <AvailabilitiesCalendar
          subject={subject}
          availabilities={availabilities}
          newAvailabilityModalOnOpen={newAvailabilityModalHandlers.onOpen}
          handleSelect={handleSelect}
        />
      </Box>
      <Box d={['block', null, 'none']}>
        <MobileAvailabilitiesCalendar
          subject={subject.name}
          availabilities={availabilities}
          newAvailabilityFormOnOpen={newAvailabilityModalHandlers.onOpen}
          handleSelect={handleSelect}
        />
      </Box>
      {selectedAvailability && (
        <AvailabilityModal
          subject={subject}
          isOpen={availabilityModalHandlers.isOpen}
          onClose={availabilityModalHandlers.onClose}
          availability={selectedAvailability}
          refetch={refetch}
        />
      )}
      <AvailabilityFormModal
        subject={subject.name}
        availabilities={availabilities}
        refetch={refetch}
        isOpen={newAvailabilityModalHandlers.isOpen}
        onClose={newAvailabilityModalHandlers.onClose}
      />
    </>
  );
};

export default ScheduleContainer;
