import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { parseISO } from 'date-fns';

import { Box, Flex, Stack, Text } from '@chakra-ui/core';

import API from 'api';
import {
  BreadcrumbNav,
  ButtonGatsbyLink,
  LoadingSpinner,
} from 'components/_lib';
import { TeacherClassFeedback } from 'types';

import ClassDetails from './ClassDetails';
import StudentFeedback from './StudentFeedback';

// FeedbackPageContainer component prop types
interface FeedbackPageContainerProps {
  classId: string;
}

/**
 * Wraps page for writing teacher feedback on a given class
 */
const FeedbackPageContainer: FC<FeedbackPageContainerProps> = ({
  classId,
}: FeedbackPageContainerProps) => {
  const { data: finishedClass, status, refetch } = useQuery(
    ['class', { classId }],
    (): Promise<TeacherClassFeedback | null> =>
      (async (): Promise<TeacherClassFeedback | null> => {
        try {
          const response = await API.get(`/teachers/classes/${classId}`);
          return response.data;
        } catch {
          return null;
        }
      })()
  );

  return status === 'loading' ? (
    <Flex minH="inherit" align="center" justify="center">
      <LoadingSpinner />
    </Flex>
  ) : !finishedClass ? (
    <>
      <BreadcrumbNav
        links={[
          { href: '/teacher/home', text: 'Home', isCurrentPage: false },
          {
            href: '/teacher/home/history',
            text: 'History',
            isCurrentPage: false,
          },
          {
            href: window.location.href,
            text: 'Feedback',
            isCurrentPage: true,
          },
        ]}
      />
      <Stack
        minH={[
          'calc(100vh - 164px - 4rem)',
          'calc(100vh - 168px - 4rem)',
          'calc(100vh - 172px - 12rem)',
          'calc(100vh - 180px - 12rem)',
        ]}
        align="center"
        justify="center"
        spacing={4}
      >
        <Text fontWeight="bold">No class found.</Text>
        <ButtonGatsbyLink
          to="/teacher/home"
          variantColor="orange"
          rounded={8}
          fontFamily="heading"
          textTransform="uppercase"
        >
          Return Home
        </ButtonGatsbyLink>
      </Stack>
    </>
  ) : (
    <>
      <BreadcrumbNav
        links={[
          { href: '/teacher/home', text: 'Home', isCurrentPage: false },
          {
            href: '/teacher/home/history',
            text: 'History',
            isCurrentPage: false,
          },
          {
            href: window.location.href,
            text: 'Feedback',
            isCurrentPage: true,
          },
        ]}
      />
      <Stack spacing={8}>
        <Stack flexDir={['column', null, 'row']} spacing={0}>
          <Box w={['100%', null, '35%']} mr={[0, null, 8]}>
            <ClassDetails
              subject={finishedClass.subject.name}
              level={finishedClass.level}
              datetime={parseISO(finishedClass.datetime)}
              durationInMinutes={finishedClass.durationInMinutes}
              isTrial={finishedClass.isTrial}
              paidAt={
                finishedClass.paidAt ? parseISO(finishedClass.paidAt) : null
              }
              size={finishedClass.students.length}
            />
          </Box>
        </Stack>
        <StudentFeedback
          students={finishedClass.students}
          classId={classId}
          refetch={refetch}
        />
      </Stack>
    </>
  );
};

export default FeedbackPageContainer;
