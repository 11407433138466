import React from 'react';
import type { FC } from 'react';

import {
  Avatar,
  Box,
  Button,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/core';
import { FaCalendarPlus } from 'react-icons/fa';

import type { Student } from 'types';

// StudentsPopover component prop types
interface StudentsPopoverProps {
  students: Pick<Student, 'id' | 'picture' | 'username'>[];
  addToCalendar?: string;
}

/**
 * Popover rendering list of students for a class
 */
const StudentsPopover: FC<StudentsPopoverProps> = ({
  students,
  addToCalendar,
}: StudentsPopoverProps) => (
  <Popover>
    <PopoverTrigger>
      <Button
        variant="ghost"
        rounded={8}
        fontWeight="normal"
        rightIcon="info-outline"
      >
        {students.length} {students.length > 1 ? 'students' : 'student'}
      </Button>
    </PopoverTrigger>
    <PopoverContent zIndex={4}>
      <PopoverArrow d="flex" />
      <PopoverBody>
        <Stack>
          {students.map((s) => (
            <Stack key={s.id} isInline align="center">
              <Avatar src={s.picture} size="sm" />
              <Text>{s.username}</Text>
            </Stack>
          ))}
        </Stack>
      </PopoverBody>
      {addToCalendar && (
        <PopoverFooter d="flex" justifyContent="center" p={3}>
          <Link
            href={addToCalendar}
            color="teal.500"
            isExternal
            d="inline-flex"
            alignItems="center"
          >
            Add to Calendar
            <Box as={FaCalendarPlus} ml={2} />
          </Link>
        </PopoverFooter>
      )}
    </PopoverContent>
  </Popover>
);

export default StudentsPopover;
