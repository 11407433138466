import React, { FC, ReactElement } from 'react';
import { format } from 'date-fns';

import { Box, Heading, List, ListIcon, ListItem, Stack } from '@chakra-ui/core';
import {
  FaFileInvoiceDollar,
  FaRegCalendarAlt,
  FaRegClock,
  FaUser,
  FaUniversity,
} from 'react-icons/fa';

// ClassDetails component prop types
interface ClassDetailsProps {
  subject: string;
  level: number;
  datetime: Date;
  durationInMinutes: number;
  isTrial: boolean;
  paidAt: Date | null;
  size: number;
}

/**
 * Wraps page for writing teacher feedback on a given class
 */
const ClassDetails: FC<ClassDetailsProps> = ({
  subject,
  level,
  datetime,
  durationInMinutes,
  isTrial,
  paidAt,
  size,
}: ClassDetailsProps) => (
  <Stack
    bg="white"
    rounded={8}
    color="teal.500"
    border="1px solid"
    borderColor="gray.300"
    align="center"
    justify="center"
  >
    <Stack
      alignItems="center"
      justifyContent="center"
      p={4}
      borderBottom="1px solid"
      borderColor="gray.100"
      w="100%"
    >
      <Heading textTransform="uppercase" size="md" fontFamily="heading">
        Class Details
      </Heading>
    </Stack>
    <List py={4} px={8} spacing={2} alignSelf="flex-start">
      <ListItem d="flex" alignItems="center">
        <ListIcon
          icon={(): ReactElement => (
            <Box as={FaUniversity} mr={2} w="1.25rem" h="1.25rem" />
          )}
        />
        {subject} {isTrial ? 'Assessment' : level}
      </ListItem>
      <ListItem d="flex" alignItems="center">
        <ListIcon
          icon={(): ReactElement => (
            <Box as={FaRegCalendarAlt} mr={2} w="1.25rem" h="1.25rem" />
          )}
        />
        {format(datetime, "ccc, PP 'at' p")}
      </ListItem>
      <ListItem d="flex" alignItems="center">
        <ListIcon
          icon={(): ReactElement => (
            <Box as={FaRegClock} mr={2} w="1.25rem" h="1.25rem" />
          )}
        />
        {durationInMinutes} min. class
      </ListItem>
      <ListItem d="flex" alignItems="center">
        <ListIcon
          icon={() => <Box as={FaUser} mr={2} w="1.25rem" h="1.25rem" />}
        />
        {size} {size === 1 ? 'student' : 'students'}
      </ListItem>
      {paidAt && (
        <ListItem d="flex" alignItems="center">
          <ListIcon
            icon={() => (
              <Box as={FaFileInvoiceDollar} mr={2} w="1.25rem" h="1.25rem" />
            )}
          />
          {format(paidAt, "'Paid' PP")}
        </ListItem>
      )}
    </List>
  </Stack>
);

export default ClassDetails;
